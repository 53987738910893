import React, {useState, useEffect} from 'react';
import { Form, Input ,Radio} from 'components/shared/FormComponent';
import { Button } from 'components/shared/ButtonComponent';
import { Modal, message } from 'components/shared/AntComponent';
import { useSelector, useDispatch } from 'components/shared/CompVariables';
import { getUserData, saveUser } from 'services/actions/securityAction';


interface ChildProps {
    openModal: boolean;
    handleProfile: () => void;
}


const UserProfile: React.FC<ChildProps> = ({openModal, handleProfile} ) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {getUserInfo, loading2, error2, success2 } = useSelector((state: any) => state.security);
    const [isActive, setIsActive] = useState(true);
    const [defaultValues, setDefaultValues]: any = useState(null);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg: any = showErrormsg ? error2 : false;

    const getUserDetail = () => {
        dispatch(getUserData(sessionStorage.getItem('userid')) as any);
    };

    useEffect(() => {
        getUserDetail();
    }, [openModal]);

  
    const loadData = (defaultValues: any) => {
        form.setFieldValue("email", defaultValues?.email ?? '');
        form.setFieldValue("firstname", defaultValues?.first_name ?? '');
        form.setFieldValue("lastname", defaultValues?.last_name ?? '');
        form.setFieldValue("mfa", defaultValues?.mfa_enabled ?? false);
    }

    useEffect(() => {
        if (getUserInfo?.data && openModal) {
            loadData(getUserInfo?.data);
            setIsActive(getUserInfo?.data?.is_active);
            setDefaultValues(getUserInfo?.data);
        }
    }, [getUserInfo?.data,openModal]);

    
    const handleCancel = () =>{
        handleProfile();
    }

    const onFinish = async () => {
        try {
            await form.validateFields();
            const value = form.getFieldsValue();

            const inputJson = {
                userid: defaultValues !== null ? defaultValues.id : 0,
                username: defaultValues?.username,
                email: value.email,
                first_name: value.firstname,
                last_name: value.lastname,
                is_active: isActive,
                accountid: defaultValues?.accountid || 0,
                groups: defaultValues?.groups,
                mfa_enabled: value?.mfa,
            };
            dispatch(saveUser(inputJson) as any)
            setShowSuccessmsg(true)
            setShowErrormsg(true)
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };
    useEffect(() => {
        if (successmsg) {
            message.success('User Profile Updated successfully');
            setShowSuccessmsg(false);
            handleProfile();
        }
        if (errormsg) {
            if (error2?.data) {
                // Format the error message
                const errorMessage = Object.entries(error2.data)
                    .map(([key, value]) => `${key}: ${(value as string[]).join(', ')}`)
                    .join('\n');
            
                message.error(errorMessage);
            } else {
                message.error("User Profile couldn't be Updated");
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    return(
        <div>
        <Modal
            title="Update Profile"
            open={openModal}
            onCancel={handleCancel}
            maskClosable={false}
            footer={[
                <Button key="back" className='bg-danger text-white' onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={onFinish}>
                    Save
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} name="basic" autoComplete="off">
                <Form.Item
                    label="Email"
                    name="email"
                    className="w-100"
                    rules={[
                        {
                            type: 'email',
                            message: 'Enter valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstname"
                    className="w-100"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastname"
                    className="w-100"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Is MFA Enabled" name="mfa" className="col w-100">
                    <Radio.Group name="radiogroup">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                
            </Form>
        </Modal>
    </div>
    )
}

export default UserProfile;